@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;400;500;600;700&display=swap');



@layer utilities {
    .bg-dark-blue-100 {
        @apply bg-[#0a212b]
    }

    .bg-light-blue-transparent {
        @apply bg-[#4d637229]
    }

    .bg-dark-blue-300 {
        @apply bg-[#061012]
    }

    .scrollbar::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    .scrollbar::-webkit-scrollbar-track {
        border-radius: 4px;
        background: #03121e;
    }

    .scrollbar::-webkit-scrollbar-thumb {
        background: #0a4968;
        border-radius: 4px;
        border: 3px solid #35667e;
    }

    .scrollbar::-webkit-scrollbar-thumb:hover {
        background: #0a4968;
    }


}



.no-tailwindcss {
    /* @apply; */
}


body{
    @apply w-screen h-dvh bg-dark-blue-300 text-[#c6e3e0] scroll-smooth scrollbar duration-100 
}